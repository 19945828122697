import { Col, Row, Typography } from "antd";
import React from "react";

//components
import ClientListClientAccountInfo from "./component/client_list_client_account_info";
//actions
//selector
//function
//str
//data
import { CLIENT_ACCOUNT } from "../../../store/data/client_list"

const { Text } = Typography;
const ClientListClientAccount = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderTitle = () => {
    return (
      <Col span={24}>
        <Text strong className="fs-16">
          Client List
        </Text>
      </Col>
    );
  };
  const renderAccountInfo = () => {
    return (
      <Col span={24}>
        <Row gutter={[0,16]}>
          {CLIENT_ACCOUNT?.map((dt) => {
            return <ClientListClientAccountInfo record={dt} />;
          })}
        </Row>
      </Col>
    );
  };
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Row gutter={[0, 16]}>
        {renderTitle()}
        {renderAccountInfo()}
      </Row>
    </Col>
  );
};
export default ClientListClientAccount;
