import { TYPES } from "./nav.types";

const initState = {
  hightlight: [],
  openMenu: false
};

const navReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.HIGHTLIGHT:
      return {
        ...state,
        hightlight: payload,
      };
      case TYPES.SET_OPEN_MENU:
      return {
        ...state,
        openMenu: payload,
      };
    default:
      return state;
  }
};

export default navReducer;
