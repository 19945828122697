import { TYPES } from "./nav.types";

// -------------------------- Snapshot --------------------------

export const setHighlight = (keys) => (dispatch) => {
  setHightLightSuccess(dispatch, keys);
};

export const setOpenMenu =
  ({ data }, callback) =>
  (dispatch) => {
    setOpenMenuSuccess(dispatch, data);
    if (callback) {
      callback();
    }
  };
// -------------------------- Dispatch --------------------------

const setHightLightSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.HIGHTLIGHT,
    payload: data,
  });
};

const setOpenMenuSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SET_OPEN_MENU,
    payload: data,
  });
};
