import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Drawer, Button, Grid, Select } from "antd";
import { FiMenu } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import LayoutMenu from "./layout_menu";
import { setOpenMenu } from "../../../store/nav/nav.action"
import { opneMenuSelector } from "../../../store/nav/nav.selector"
const { useBreakpoint } = Grid;

const LayoutHeader = () => {
  // -------------------------- VAR --------------------------
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  // -------------------------- STATE --------------------------
  const [lang, setLang] = useState("en");
  const [span, setSpan] = useState(3);
  const screens = useBreakpoint();

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch()
  const openMenu = useSelector(opneMenuSelector)
  // -------------------------- FUNCTION --------------------------

  const toggleDrawer = () => {
    dispatch(setOpenMenu({data: !openMenu}))
  };

  const closeDrawer = () => {
    dispatch(setOpenMenu({data: false}))
  };

  // -------------------------- EFFECT --------------------------

  useEffect(() => {
    setLang(currentLang);
  }, [currentLang]);

  useEffect(() => {
    let span = 24;
    if (screens?.xs) {
      span = 1;
    }
    if (screens?.sm) {
      span = 1;
    }
    if (screens?.md) {
      span = 2;
    }
    if (screens?.lg) {
      span = 3;
    }
    if (screens?.xl) {
      span = 3;
    }
    if (screens?.xxl) {
      span = 3;
    }
    setSpan(span);
  }, [screens]);

  // -------------------------- RENDER --------------------------

  const renderLogo = () => {
    return (
      <a href="/" target="_self" rel="noopener noreferrer">
        <div className="logo " />
      </a>
    );
  };

  const renderDestop = () => {
    return (
      <Row justify="space-between" align="middle">
        <Col flex="150px">{renderLogo()}</Col>
        <Col flex="auto">
          <LayoutMenu mode="horizontal" />
        </Col>
      </Row>
    );
  };

  const renderMobile = () => {
    return (
      <Row justify="space-between" align="middle">
        <Col flex="none">{mobileButton()}</Col>
        <Col flex="auto">{renderLogo()}</Col>
        {mobileDrawer()}
      </Row>
    );
  };

  const mobileButton = () => {
    return (
      <Button
        shape="default"
        onClick={() => toggleDrawer()}
        style={{ padding: 6, borderRadius: 5 }}
      >
        <FiMenu style={{ fontSize: 18 }} />
      </Button>
    );
  };

  const mobileDrawer = () => {
    return (
      <Drawer
        placement="left"
        closable={true}
        onClose={() => closeDrawer()}
        visible={openMenu}
        className="drawer-container"
        width={300}
      >
        <LayoutMenu mode="inline" />
      </Drawer>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Layout.Header className="header-menu">
      {span < 3 ? renderMobile() : renderDestop()}
    </Layout.Header>
  );
};

export default LayoutHeader;
