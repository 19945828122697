import { Typography, Row, Space, Col } from "antd";
import React from "react";
import { FaChevronDown } from "react-icons/fa";

//components
//actions
//selector
//function
//str

const { Text } = Typography;
const BotTradingCommissionStatistical = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderCount = () => {
    return (
      <Col span={24}>
        <Row justify="center">
          <Text strong className="fs-16" type="success">+ 1,206 USD</Text>
        </Row>
      </Col>
    );
  };
  const renderUnit = () => {
    return (
      <Col span={24}>
        <Row justify="center">
          <Text strong className="fs-16">25 Dec - 30 Dec 2023</Text>
        </Row>
      </Col>
    );
  };
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Row align="middle" justify="center">
        {renderCount()}
        {renderUnit()}
      </Row>
    </Col>
  );
};
export default BotTradingCommissionStatistical;
