import { Card, Space } from "antd";
import React from "react";

//components
import ClientListClientAccountInfoDetailItem from "./component/client_list_client_account_info_detail_item";
//actions
//selector
//function
//str

const ClientListClientAccountInfoDetail = ({ info }) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Card className="client-account-info-card">
      <Space direction="vertical" className="w-full">
        <ClientListClientAccountInfoDetailItem
          label="Account registration date"
          value={info?.accountRegistrationDate}
        />
        <ClientListClientAccountInfoDetailItem
          label="Last investing date"
          value={info?.lastInvestingDate}
        />
        <ClientListClientAccountInfoDetailItem
          label="Last investing bot"
          value={info?.lastInvestingBot}
        />
        <ClientListClientAccountInfoDetailItem
          label="Volume"
          value={info?.volume}
        />
      </Space>
    </Card>
  );
};
export default ClientListClientAccountInfoDetail;
