import { Form } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

//components
import PromoteCodeCommissionFilterInfoAccountRedeemedDate from "./component/promote_code_commission_filter_info_account_redeemed_date"
import PromoteCodeCommissionFilterInfoClientSubmit from "./component/promote_code_commission_filter_info_submit"
import PromoteCodeCommissionFilterInfoClientUserName from "./component/promote_code_commission_filter_info_client_user_name"
//actions
import { setOpenFiter } from "../../../../store/botTradingCommnission/bot_trading_commission.action";
//selector
//function
//str

const PromoteCodeCommissionFilterInfo = () => {
  // -------------------------- VAR ----------------------------
  const [form] = Form.useForm();
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION ---------------------
  const onFinish = () => {
    dispatch(setOpenFiter({ data: false }));
  };
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form
      requiredMark={false}
      size="small"
      autoComplete="off"
      onFinish={onFinish}
      form={form}
      id="form-filter-promote-code-commission"
      layout="vertical"
    >
      <PromoteCodeCommissionFilterInfoClientUserName form={form} />
      <PromoteCodeCommissionFilterInfoAccountRedeemedDate form={form} />
      <PromoteCodeCommissionFilterInfoClientSubmit />
    </Form>
  );
};
export default PromoteCodeCommissionFilterInfo;
