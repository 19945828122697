import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { app } from "../../config/config";
import { REF } from "../ref";
import { TYPES } from "./bot_trading_commission.type";

const firestore = getFirestore(app);

// fire base

// action

export const setOpenFiter =
  ({ data }, callback) =>
  (dispatch) => {
    setOpenFilterSuccess(dispatch, data);
    if (callback) {
      callback();
    }
  };

// dispatch
const setOpenFilterSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SET_OPEN_FILTER,
    payload: data,
  });
};
