import { Col, Row, Typography, Timeline, Space } from "antd";
import React from "react";
//components
import PartnerLevelPartnerStructureTimeLineManageItem from "./component/partner_level_partner_structure_time_line_manage_item";
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelPartnerStructureTimeLineAreaManager = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderLogo = () => {
    return (
      <div className="time-line-item-icon time-line-item-icon-area" />
    );
  };
  const renderInfo = () => {
    return (
      <PartnerLevelPartnerStructureTimeLineManageItem
        title="Area Manager  "
        commission="10% commission "
        description="Approved by LiveTrade and locked fund 5,000 USD"
      />
    );
  };
  // -------------------------- MAIN ------------------------
  return (
    <Col span={24}>
      <Row>
        {renderLogo()}
        {renderInfo()}
      </Row>
    </Col>
  );
};
export default PartnerLevelPartnerStructureTimeLineAreaManager;
