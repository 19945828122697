import { Col, Table } from "antd";
import React from "react";

//components
//actions
//selector
//function
//str
//data

const BotTradingCommissionTableDetailExpendTable = ({record}) => {
  // -------------------------- VAR ----------------------------
  const columns = [
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
    },
    {
      title: "Invested Fund",
      dataIndex: "investedFund",
      key: "investedFund",
    },
    {
      title: "Fund Allocation",
      dataIndex: "fundAllocation",
      key: "fundAllocation",
      align:"right"
    },
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Table
        columns={columns}
        dataSource={record}
        pagination={false}
        scroll={{x:true}}
      />
    </Col>
  );
};
export default BotTradingCommissionTableDetailExpendTable;
