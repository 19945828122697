import { Row, Col, Card, Typography, Radio, Space } from "antd";
import React from "react";
import { IoWalletOutline } from "react-icons/io5";

//components
import HomeScreenCardHeader from "./component/home-screen-card-header";
import HomeScreenCardValue from "./component/home-screen-card-value"
//actions
//selector
//function
//str

const { Title } = Typography;

const HomeScreenCardBalance = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderHeader = () => {
    return (
      <HomeScreenCardHeader
        label="Balance"
        icon={<IoWalletOutline size={26} />}
      />
    );
  };
  const renderBalance = () => {
    return (
      <HomeScreenCardValue value="101.18 USD" />
    );
  };
  const renderTabs = () => {
    return (
      <Row justify="center">
        <Radio.Group className="custom-tabs">
          <Radio.Button>Withdraw</Radio.Button>
          <Radio.Button>History</Radio.Button>
        </Radio.Group>
      </Row>
    );
  };
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Card className="custom-padding-card">
        <Space direction="vertical" className="w-full">
          {renderHeader()}
          {renderBalance()}
          {renderTabs()}
        </Space>
      </Card>
    </Col>
  );
};
export default HomeScreenCardBalance;
