import { Col, Collapse, Row } from "antd";
import React from "react";

//components
import BotTradingCommissionTableDetailExpendTable from "./component/bot_trading_commission_table_detail_expend_table";
//actions
//selector
//function
//str

const BotTradingCommissionTableDetailExpend = ({ record }) => {
  // -------------------------- VAR ----------------------------
  const items = [
    {
      key: '1',
      label: 'Show clients fund allocation',
      children: <BotTradingCommissionTableDetailExpendTable record={record} />,
    }
  ];
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------

  return (
    <Row justify="space-between" align="center">
      <Col span={24}>
        <Collapse
          expandIconPosition="end"
          items={items}
        />
      </Col>
    </Row>
  );
};
export default BotTradingCommissionTableDetailExpend;
