import { Row, Typography, Col, Card } from "antd";
import React from "react";
//components
import PartnerLevelYourAreaManageDetailInfo from "./component/partner_level_your_area_manage_detail_info"
import PartnerLevelYourAreaManageDetailAction from "./component/partner_level_your_area_manage_detail_action"
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelYourAreaManageDetail = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Card className="custom-card-area-manage">
        <Row align='middle'>
            <PartnerLevelYourAreaManageDetailInfo />
            <PartnerLevelYourAreaManageDetailAction isManage={false} />
        </Row>
      </Card>
    </Col>
  );
};
export default PartnerLevelYourAreaManageDetail;
