export const PROMOTE_CODE = [
    {
        client: "Hana Ngo",
        redeemedDate: "25 Dec 2023",
        commission: "+ 20 USD"
    },
    {
        client: "Anh Nguyen",
        redeemedDate: "26 Dec 2023",
        commission: "+ 20 USD"
    }
]