import { Row, Typography, Col } from "antd";
import React from "react";
//components
import PartnerLevelRecordsInfo from "./component/partner_level_records_info";
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelRecords = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderTitle = () => {
    return (
      <Col span={24}>
        <Text className="fs-16">Your Records</Text>
      </Col>
    );
  };
  const renderRecordsInfo = () => {
    return <PartnerLevelRecordsInfo />;
  };
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Row gutter={[0, 12]}>
      {renderTitle()}
      {renderRecordsInfo()}
    </Row>
    </Col>
  );
};
export default PartnerLevelRecords;
