import { MailOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import React from "react";

const LoginFormEmail = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: "Please input Email",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          type: "email",
          message: "Wrong format Email",
        },
      ]}
    >
      <Input
        prefix={<MailOutlined className="form-item-icon" />}
        placeholder="Email"
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default LoginFormEmail;
