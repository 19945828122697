export const CLIENT_ACCOUNT = [
    {
        name: "Nghia Le",
        accountRegistrationDate: "26 Dec 2023",
        lastInvestingDate: "28 Dec 2023",
        lastInvestingBot: "Bot01-John",
        volume: "200 USD"
    },
    {
        name: "Hana Ngo",
        accountRegistrationDate: "26 Dec 2023",
        lastInvestingDate: "28 Dec 2023",
        lastInvestingBot: "Bot01-John",
        volume: "2000 USD"
    }
]

export const BOTS = [
    {label: "All", value: "all"},
    {label: "BOT 01", value: "bot01"},
    {label: "BOT 02", value: "bot02"}
]