export const handleCoppy = (value) => {
    if (navigator.clipboard && navigator.permissions) {
        navigator.clipboard.writeText(value)
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = value
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
    }
}