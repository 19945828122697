import { Collapse, Col } from "antd";
import React from "react";

//components
import ClientListClientAccountInfoDetail from "./component/client_list_client_account_info_detail"
//actions
//selector
//function
//str

const ClientListClientAccountInfo = ({record}) => {
  // -------------------------- VAR ----------------------------
  const items = [
    {
      key: "1",
      label: record?.name,
      children: <ClientListClientAccountInfoDetail info={record} />,
    },
  ];
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Collapse expandIconPosition="end" items={items} />
    </Col>
  );
};
export default ClientListClientAccountInfo;
