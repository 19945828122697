import { DatePicker, Form } from "antd";
import moment from "moment";
//components
//actions
//selector
//type

//function
//str

const ClientListFilterInfoClientAccountRegistrationDate = ({ form }) => {
  // -------------------------- VAR ----------------------------
  const userName = Form.useWatch("accountRegistrationDate", form);
  const dateFormat = "DD MMM YYYY";
  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form.Item
      label="Account registration date"
      name="accountRegistrationDate"
      value={userName}
    >
      <DatePicker.RangePicker
        format={dateFormat}
        className="w-full"
        size="middle"
        disabledDate={(current) => current.isAfter(moment())}
      />
    </Form.Item>
  );
};
export default ClientListFilterInfoClientAccountRegistrationDate;
