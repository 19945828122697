import { Card, Col, Space, Typography } from "antd";
import React from "react";
import { FaUsers } from "react-icons/fa6";

//components
import HomeScreenCardHeader from "./home-screen-card-header";
import HomeScreenCardValue from "./home-screen-card-value";
import HomeScreenChartRegistrationsChart from "./component/home-screen-chart-registrations-chart";
//actions
//selector
//function
//str

const HomeScreenChartRegistrations = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderHeader = () => {
    return (
      <HomeScreenCardHeader
        label="Registrations"
        icon={<FaUsers size={26} />}
      />
    );
  };
  const renderRegistrationsCount = () => {
    return <HomeScreenCardValue value="41" />;
  };
  const renderChart = () => {
    return <HomeScreenChartRegistrationsChart />;
  };
  // -------------------------- MAIN ---------------------------
  return (
    <Space direction="vertical" className="w-full">
      {renderHeader()}
      {renderRegistrationsCount()}
      {renderChart()}
    </Space>
  );
};
export default HomeScreenChartRegistrations;
