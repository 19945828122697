import { Col, Row, Typography } from "antd";
import React from "react";

//components
//actions
//selector
//function
//str

const { Text } = Typography;
const HomeScreenCardHeader = ({icon, label }) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Row gutter={[12, 12]} align="middle" justify="start">
      <>
        {icon}
      </>
      <Text className="fs-title ml-12">{label}</Text>
    </Row>
  );
};
export default HomeScreenCardHeader;
