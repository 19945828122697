import { Typography, Row, Space, Col } from "antd";
import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch } from "react-redux";

//components
//actions
import { setOpenMenu } from "../../../store/nav/nav.action"

//selector
//function
//str

const { Text } = Typography;
const PromoteCodeCommissionHeader = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch()
  // -------------------------- FUNCTION ---------------------
  const handleClick = () => {
    dispatch(setOpenMenu({data: true}))
  }
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Row align="middle">
        <Space>
          <Text strong className="fs-16">
            Promote Code Commission
          </Text>
          <FaChevronDown size={16} className="mt-3" onClick={handleClick} />
        </Space>
      </Row>
    </Col>
  );
};
export default PromoteCodeCommissionHeader;
