import { TYPES } from "./auth.type";

const initState = {
  isLogged: true,
  loading: false,
};

const authReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.FIREBASE_AUTH_STATUS:
      return {
        ...state,
        isLogged: payload,
      };
      case TYPES.LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case TYPES.LOGIN_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
