import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  onAuthStateChanged
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app, MASTER_EMAIL } from "../../config/config"
import { REGION } from "../ref"
import { TYPES } from "./auth.type";

const functions = getFunctions(app, REGION);

// -------------------------- LOGIN --------------------------
export const firebaseLogin =
  ({ login }, callback) =>
  (dispatch) => {
    firebaseLoading(dispatch);
    const email = login.email;
    const password = login.password;

    if (email === MASTER_EMAIL) {
      loginWithAdmin({
        email,
        password,
        dispatch,
        callback,
      });
    } else {
      loginWithOperator({ email, password, dispatch, callback });
    }
  };

// By Admin
const loginWithAdmin = ({ email, password, dispatch, callback }) => {
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email, password)
    .then((user) => {
      console.log({ user });
      if (callback) {
        callback({
          status: 200,
          data: "Successfully",
        });
      }
      firebaseLoginSuccess(dispatch);
    })
    .catch((error) => {
      if (callback) {
        callback({
          status: 500,
          data: error.message,
        });
      }
      firebaseLoginFail(dispatch, error.message);
    });
};

// By Operator
const loginWithOperator = ({ email, password, dispatch, callback }) => {
  const auth = getAuth();
  const accessToken = httpsCallable(functions, "api-operator-token-get");
  accessToken({ email, password })
    .then((response) => {
      console.log({ response });
      if (response?.data?.status === 200) {
        const token = response.data.data;
        signInWithCustomToken(auth, token)
          .then(() => {
            if (callback) {
              callback({
                status: 200,
                data: "Successfully",
              });
            }
            firebaseLoginSuccess(dispatch);
          })
          .catch((error) => {
            if (callback) {
              callback({
                status: 500,
                data: error.message,
              });
            }
            firebaseLoginFail(dispatch, error.message);
          });
      } else {
        if (callback) {
          callback({
            status: 500,
            data: "Account not found or wrong password",
          });
        }
        firebaseLoginFail(dispatch, "Account not found or wrong password");
      }
    })
    .catch((error) => {
      if (callback) {
        callback({
          status: 500,
          data: "Account not found or wrong password" || error?.message,
        });
      }
      firebaseLoginFail(dispatch, "Account not found or wrong password");
    });
};

// -------------------------- Logout --------------------------

export const firebaseLogout = (callback) => (dispatch) => {
  const auth = getAuth();
  signOut(auth).then(() => {});
  firebaseLogoutSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const setFakeLogin =
  ({ data }, callback) =>
  (dispatch) => {
    firebaseAuthSuccess(dispatch, data);
    if (callback) {
      callback();
    }
  };

// -------------------------- Snapshot --------------------------
const authSub = [];
export const snapAuth = () => (dispatch) => {
  const auth = getAuth();
  const unSub = onAuthStateChanged(auth, () => {
    const isLogged = getAuth()?.currentUser?.uid ? true : false;
    firebaseAuthSuccess(dispatch, isLogged);
  });
  authSub.push(unSub);
};

export const unSnapAuth = () => (dispatch) => {
  authSub.forEach((subscriber) => {
    subscriber();
  });
  authSub.length = 0;
};

// -------------------------- Dispatch --------------------------
const firebaseAuthSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.FIREBASE_AUTH_STATUS,
    payload: data,
  });
};

const firebaseLoading = (dispatch) => {
  dispatch({
    type: TYPES.LOGIN_LOADING,
  });
};

const firebaseLoginSuccess = (dispatch) => {
  dispatch({
    type: TYPES.LOGIN_SUCCESS,
  });
};

const firebaseLoginFail = (dispatch, error) => {
  dispatch({
    type: TYPES.LOGIN_FAILED,
    payload: error,
  });
};

const firebaseLogoutSuccess = (dispatch) => {
  dispatch({
    type: TYPES.LOGOUT_SUCCESS,
  });
};