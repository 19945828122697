import { Form, InputNumber } from "antd";
//components
//actions
//selector
//type

//functionO
//str

const BotTradingCommissionFilterInfoFundAllocation = ({ form }) => {
  // -------------------------- VAR ----------------------------
  const fundAllocation = Form.useWatch("fundAllocation", form);
  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form.Item
      label="Fund Allocation"
      name="fundAllocation"
      value={fundAllocation}
    >
      <InputNumber
        placeholder="0 - 100%"
        value={fundAllocation}
        size="middle"
        className="w-full"
      />
    </Form.Item>
  );
};
export default BotTradingCommissionFilterInfoFundAllocation;