import { Col, Input, Row } from "antd";
import React from "react";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";

//components
import PartnerLevelHeader from "./component/partner_level_header";
import PartnerLevelYourAreaManageDetail from "./component/partner_level_your_area_manage_detail"
//actions
import { setSearch } from "../../../store/partnerLevel/partner_level.action";
//selector
import { searchSelector } from "../../../store/partnerLevel/partner_level.selector";
//function
//str


const PartnerLevelYourAreaManager = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);
  // -------------------------- FUNCTION ---------------------
  const handleChange = (e) => {
    dispatch(setSearch({ data: e?.target?.value }));
  };
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderTitle = () => {
    return <PartnerLevelHeader title="Your Area Manager " />;
  };
  const renderSearch = () => {
    return (
      <Col span={24}>
        <Input
          placeholder="Search manager by email"
          value={search}
          prefix={<CiSearch size={20} />}
          onChange={handleChange}
          size="middle"
        />
      </Col>
    );
  };
  const renderDetail = () => {
    return (
      <PartnerLevelYourAreaManageDetail />
    )
  }
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Row gutter={[0, 8]}>
        {renderTitle()}
        {renderSearch()}
        {renderDetail()}
      </Row>
    </Col>
  );
};
export default PartnerLevelYourAreaManager;
