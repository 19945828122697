import { initializeApp } from "firebase/app";
export const BRAND_NAME = "AI Fund Partners"; // Brand Name

export const SANDBOX = false;

export const firebaseConfig = {
  apiKey: "AIzaSyA37SlUrmlMwLNH0dbCwXU2obgukV_UIGc",
  authDomain: "ai-fund-b64d0.firebaseapp.com",
  projectId: "ai-fund-b64d0",
  storageBucket: "ai-fund-b64d0.appspot.com",
  messagingSenderId: "800239332868",
  appId: "1:800239332868:web:1f64207e4d4c345f15cf51",
  measurementId: "G-66KF3R44PE",
};

export const reCAPTCHA = "6LeooRcpAAAAAIXXGdbEpw9p4GpPAZzBOsICWuU0";

export const app = initializeApp(firebaseConfig);

// ========================= App String ========================= //

export const VERSION = "v0.1.56";
export const WEB_DOMAIN = "";
export const MEDIA_STORAGE =
  "https://storage.googleapis.com/" + firebaseConfig.storageBucket + "/";

export const TIME_ZONE = {
  tz: "Asia/Ho_Chi_Minh",
  label: "AEST",
};

export const MASTER_EMAIL = "master@partners.livetrade.finance"; //
