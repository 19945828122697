import React from "react";
import { Route } from "react-router-dom";
import LoginScreen from "../01_Login/login_screen";
import { URL } from "./url";

const RoutePublict = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <>
      <Route exact path={URL.home} element={<LoginScreen />} />
    </>
  );
};

export default RoutePublict;
