import { Form, Select } from "antd";
//components
//actions
//selector
//type

//function
//str
// data
import { BOTS } from "../../../../../store/data/client_list";

const ClientListFilterInfoClientLastInvestingBot = ({ form }) => {
  // -------------------------- VAR ----------------------------
  const lastInvestingBot = Form.useWatch("lastInvestingBot", form);
  // -------------------------- REDUX -------------------------
  // -------------------------- FUNCTION ---------------------
  const handleChangeBot = (evt) => {
    form.setFieldsValue({ lastInvestingBot: evt });
  };
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form.Item
      label="Last investing bot"
      name="lastInvestingBot"
      value={lastInvestingBot}
    >
      <Select
        className="w-full"
        placeholder="Select pair"
        onChange={handleChangeBot}
        size="middle"
        value={lastInvestingBot}
      >
        {BOTS?.map((dt) => {
          return (
            <Select.Option key={dt?.value} value={dt?.value}>
              {dt?.label}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
export default ClientListFilterInfoClientLastInvestingBot;