import { Row, DatePicker, Space } from "antd";
import React from "react";
import moment from "moment";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
//components
//actions
import { setFromDate, setToDate } from "../../../../store/home/home.action";
//selector
import {
  homeFromdateSelector,
  homeTodateSelector,
} from "../../../../store/home/home.selector";
//function
import { disableDateRanges } from "../../utils/date.function";
//str

const { RangePicker } = DatePicker;
const HomeScreenCardChartFilter = () => {
  // -------------------------- VAR ---------------------------
  const dateFormat = "DD/MM/YYYY";
  //------------------------ STATE --------------------------

  // -------------------------- REDUX -------------------------
  const dispatch = useDispatch();
  const fromDate = useSelector(homeFromdateSelector);
  const toDate = useSelector(homeTodateSelector);

  const start = dayjs(fromDate?._d);
  const end = dayjs(toDate?._i);
  // -------------------------- FUNCTION ---------------------
  const onChange = (value) => {
    const from = moment(value[0]?.$d);
    dispatch(setFromDate({ data: from }));
    const end = moment(value[1]?.$d).endOf("day");
    dispatch(setToDate({ data: end }));
  };
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderPicker = () => {
    return (
      <RangePicker
        className="full"
        onChange={onChange}
        format={dateFormat}
        disabledDate={disableDateRanges({ endDate: new Date() }, dateFormat)}
        value={[start, end]}
      />
    );
  };
  // -------------------------- MAIN ---------------------------
  return (
    <Row justify="start">{renderPicker()}</Row>
  );
};
export default HomeScreenCardChartFilter;
