import { DatePicker, Form } from "antd";
import moment from "moment";
//components
//actions
//selector
//type

//function
//str

const BotTradingCommissionFilterInfoClientLastInvestingPeriod = ({ form }) => {
  // -------------------------- VAR ----------------------------
  const lastInvestingPeriod = Form.useWatch("lastInvestingPeriod", form);
  const dateFormat = "DD MMM YYYY";
  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form.Item
      label="Last investing period"
      name="lastInvestingPeriod"
      value={lastInvestingPeriod}
    >
      <DatePicker.RangePicker
        format={dateFormat}
        className="w-full"
        size="middle"
        disabledDate={(current) => current.isAfter(moment())}
      />
    </Form.Item>
  );
};
export default BotTradingCommissionFilterInfoClientLastInvestingPeriod;
