import { Row, Typography } from "antd";
import React from "react";

//components
//actions
//selector
//function
//str

const { Title } = Typography;
const HomeScreenCardValue = ({value}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Row justify="center">
        <Title level={3} strong className="m-0">
          {value}
        </Title>
      </Row>
  );
};
export default HomeScreenCardValue;
