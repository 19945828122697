import { Col, Row, Typography } from "antd";
import React from "react";
//components
import PartnerLevelPartnerStructureTimeLineLeaderItem from "./component/partner_level_partner_structure_time_line_leader_item";
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelPartnerStructureTimeLineSilver = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderLogo = () => {
    return (
      <Col>
        <div className="time-line-item-icon" />
      </Col>
    );
  };
  const renderDiamondLogo = () => {
    return (
      <Col>
          <div className="time-line-item-icon-small time-line-item-icon-silver"/>
      </Col>
    )
  }
  const renderInfo = () => {
    return (
      <PartnerLevelPartnerStructureTimeLineLeaderItem
        logo={renderDiamondLogo()}
        commission="30% commission "
        description="< 50 Active Clients and Total Fund > 10,000 USD"
      />
    );
  };
  // -------------------------- MAIN ------------------------
  return (
    <Col span={24}>
      <Row>
        {renderLogo()}
        {renderInfo()}
      </Row>
    </Col>
  );
};
export default PartnerLevelPartnerStructureTimeLineSilver;
