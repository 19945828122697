import { Col, Table } from "antd";
import React from "react";

//components
//actions
//selector
//function
//str
//data
import { PROMOTE_CODE } from "../../../store/data/promote_code"

const PromoteCodeCommissionTable = () => {
  // -------------------------- VAR ----------------------------
  const columns = [
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
    },
    {
      title: "Redeemed Date",
      dataIndex: "redeemedDate",
      key: "redeemedDate",
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
    }
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Table
        columns={columns}
        dataSource={PROMOTE_CODE}
        pagination={false}
        className="custom-promote_code_commision-table"
      />
    </Col>
  );
};
export default PromoteCodeCommissionTable;
