import { Row, Typography, Col, Card } from "antd";
import React from "react";
//components
import PartnerLevelRecordsInfoClient from "./component/partner_level_records_info_client"
import PartnerLevelRecordsInfoBalance from "./component/partner_level_records_info_balance"
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelRecordsInfo = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
    const renderDivider = () => {
        return (
            <Col span={2}>
                <Row justify="center">
                    <div className="custom-divider" />
                </Row>
            </Col>
        )
    }
    const renderClients = () => {
        return (
            <PartnerLevelRecordsInfoClient label="9 Clients" />
        )
    }
    const renderBalance = () => {
        return (
            <PartnerLevelRecordsInfoBalance label="844 USD" />
        )
    }
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Card className="custom-card h-120">
        <Row>
            {renderClients()}
            {renderDivider()}
            {renderBalance()}
        </Row>
      </Card>
    </Col>
  );
};
export default PartnerLevelRecordsInfo;
