import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { app } from "../../config/config";
import { REF } from "../ref";
import { TYPES } from "./home.type";

const firestore = getFirestore(app);

// fire base

// action

export const setFromDate =
  ({ data }, callback) =>
  (dispatch) => {
    setFromSuccess(dispatch, data);
    if (callback) {
      callback();
    }
  };

export const setToDate =
  ({ data }, callback) =>
  (dispatch) => {
    setToSuccess(dispatch, data);
    if (callback) {
      callback();
    }
  };

// dispatch
const setFromSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SET_FROM_DATE_SUCCESS,
    payload: data,
  });
};
const setToSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SET_TO_DATE_SUCCESS,
    payload: data,
  });
};
