import { Form } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

//components
import BotTradingCommissionFilterInfoBot from "./component/bot_trading_commission_filter_info_bot";
import BotTradingCommissionFilterInfoLot from "./component/bot_trading_commission_filter_info_lot";
import BotTradingCommissionFilterInfoFundAllocation from "./component/bot_trading_commission_filter_info_fund_allocation";
import BotTradingCommissionFilterInfoClientLastInvestingPeriod from "./component/bot_trading_commission_filter_info_last_investing_period";
import BotTradingCommissionFilterInfoClientSubmit from "./component/bot_trading_commission_filter_info_submit"
//actions
import { setOpenFiter } from "../../../../store/botTradingCommnission/bot_trading_commission.action";
//selector
//function
//str

const BotTradingCommissionFilterInfo = () => {
  // -------------------------- VAR ----------------------------
  const [form] = Form.useForm();
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION ---------------------
  const onFinish = () => {
    dispatch(setOpenFiter({ data: false }));
  };
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form
      requiredMark={false}
      size="small"
      autoComplete="off"
      onFinish={onFinish}
      form={form}
      id="form-filter-bot-trading-commission"
      layout="vertical"
    >
      <BotTradingCommissionFilterInfoClientLastInvestingPeriod />
      <BotTradingCommissionFilterInfoBot />
      <BotTradingCommissionFilterInfoLot />
      <BotTradingCommissionFilterInfoFundAllocation />
      <BotTradingCommissionFilterInfoClientSubmit />
    </Form>
  );
};
export default BotTradingCommissionFilterInfo;
