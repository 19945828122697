import { Col, Typography } from "antd";
import React from "react";
//components
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelHeader = ({ title }) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Text className="fs-16">{title}</Text>
    </Col>
  );
};
export default PartnerLevelHeader;
