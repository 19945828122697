import { Form, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

//components
import ClientListFilterInfoClientUserName from "./component/client_list_filter_info_client_user_name";
import ClientListFilterInfoClientLastInvestingVolume from "./component/client_list_filter_info_client_last_investing_volume"
import ClientListFilterInfoClientLastInvestingBot from "./component/client_list_filter_info_client_last_investing_bot"
import ClientListFilterInfoClientAccountRegistrationDate from "./component/client_list_filter_info_client_account_registration_date"
import ClientListFilterInfoClientSubmit from "./component/client_list_filter_info_client_submit"
//actions
import { setOpenFiter } from "../../../../store/clientList/client_list_action"
//selector
//function
//str

const ClientListFilterInfo = () => {
  // -------------------------- VAR ----------------------------
  const [form] = Form.useForm();
  const initialValues = {
    lastInvestingBot: 'all',
  };
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch()
  // -------------------------- FUNCTION ---------------------
  const onFinish = () => {
    dispatch(setOpenFiter({data: false}))
  }
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form
      requiredMark={false}
      size="small"
      autoComplete="off"
      onFinish={onFinish}
      form={form}
      id="form-filter-client"
      layout="vertical"
      initialValues={initialValues}
    >
      <ClientListFilterInfoClientUserName form={form} />
      <ClientListFilterInfoClientLastInvestingVolume form={form} />
      <ClientListFilterInfoClientLastInvestingBot form={form} />
      <ClientListFilterInfoClientAccountRegistrationDate form={form} />
      <ClientListFilterInfoClientSubmit />
    </Form>
  );
};
export default ClientListFilterInfo;
