import moment from "moment";
import { TYPES } from "./home.type";

const initState = {
  fromDate: moment().startOf("day"),
  toDate: moment().endOf("day"),
};

const homeReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set commision from date
    case TYPES.SET_FROM_DATE_SUCCESS:
      return {
        ...state,
        fromDate: payload,
      };
    // set commision to date
    case TYPES.SET_TO_DATE_SUCCESS:
      return {
        ...state,
        toDate: payload,
      };
    //default
    default:
      return state;
  }
};

export default homeReducer;
