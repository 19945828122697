import { Layout, Row } from "antd";
import React, {useEffect} from "react";
import { useDispatch } from "react-redux"

//components
import BotTradingCommissionHeader from "./component/bot_trading_commission_header"
import BotTradingCommnissionFilter from "./component/bot_trading_commission_filter"
import BotTradingCommissionStatistical from "./component/bot_trading_commission_statistical"
import BotTradingCommissionTable from "./component/bot_trading_commission_table"
//actions
import { setHighlight } from "../../store/nav/nav.action"

//selector
//function
//str
const { Content } = Layout;
const BotTradingCommissionScreen = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch()
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  useEffect(() => {
    dispatch(setHighlight(["3","3.2"]));
  }, [dispatch]);
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Layout className="full unselectable">
      <Content className="content p-20">
        <Row gutter={[20, 18]}>
            <BotTradingCommissionHeader />
            <BotTradingCommnissionFilter />
            <BotTradingCommissionStatistical />
            <BotTradingCommissionTable />
        </Row>
      </Content>
    </Layout>
  );
};
export default BotTradingCommissionScreen;
