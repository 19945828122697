import { Typography, Row, Table, Col } from "antd";
import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

//components
import BotTradingCommissionTableCommissionItem from "./component/bot_trading_commission_table_commission_item"
import BotTradingCommissionTableDetail from "./component/bot_trading_commission_table_detail"
//actions
//selector
//function
//str
//data
import { BOT_TRADING } from "../../../store/data/bot_trading"

const BotTradingCommissionTable = () => {
  // -------------------------- VAR ----------------------------
  const columns = [
    {
      title: "Pay Date",
      dataIndex: "payDate",
      key: "payDate",
    },
    {
      title: "Bot",
      dataIndex: "bot",
      key: "bot",
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      render: (value) => {
        return <BotTradingCommissionTableCommissionItem commission={value} />
      }
    },
    Table.EXPAND_COLUMN,
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX ----------------------------
  // -------------------------- FUNCTION ---------------------
  const expandIcon = ({ expanded, onExpand, record }) => {
    if (expanded) {
      return <FaChevronUp onClick={(e) => onExpand(record, e)} />;
    }
    return <FaChevronDown onClick={(e) => onExpand(record, e)} />;
  };
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <BotTradingCommissionTableDetail record={record} />
          ),
          expandIcon,
        }}
        dataSource={BOT_TRADING}
        className="custom-commision-table"
      />
    </Col>
  );
};
export default BotTradingCommissionTable;
