import { Col, Radio, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

//components
//actions
import { setActiveTab } from "../../../store/clientList/client_list_action";
//selector
import { activeTabSelector } from "../../../store/clientList/client_list.selector";
//function
//str
import { CLIENT_LIST_TAB } from "../../../store/string";

const ClientListTab = () => {
  // -------------------------- VAR ----------------------------
  const options = [
    { label: "Promote Code", value: CLIENT_LIST_TAB.PROMOTE_CODE },
    { label: "Referral Code", value: CLIENT_LIST_TAB.REFERAL_CODE },
  ];
  // -------------------------- STATE -------------------------
  // -------------------------- REDUX -------------------------
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabSelector);
  // -------------------------- FUNCTION ---------------------
  const handleChange = ({ target: { value } }) => {
    dispatch(setActiveTab({ data: value }));
  };
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Row align="middle" className="custom-tab">
        <Col span={24}>
          <Radio.Group
            options={options}
            value={activeTab}
            onChange={handleChange}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
      </Row>
    </Col>
  );
};
export default ClientListTab;
