import { Col, Timeline } from "antd";
import React from "react";
//components
import PartnerLevelPartnerStructureTimeLineAreaManager from "./component/partner_level_partner_structure_time_line_area_manager";
import PartnerLevelPartnerStructureTimeLineCountryManager from "./component/partner_level_partner_structure_time_line_country_manager";
import PartnerLevelPartnerStructureTimeLineLeader from "./component/partner_level_partner_structure_time_line_leader";
import PartnerLevelPartnerStructureTimeLineDiamond from "./component/partner_level_partner_structure_time_line_diamond";
import PartnerLevelPartnerStructureTimeLineGold from "./component/partner_level_partner_structure_time_line_gold";
import PartnerLevelPartnerStructureTimeLineSilver from "./component/partner_level_partner_structure_time_line_silver";
import PartnerLevelPartnerStructureTimeLineBronze from "./component/partner_level_partner_structure_time_line_bronze"
//actions
//selector
//function
//str

const PartnerLevelPartnerStructureTimeLine = () => {
  // -------------------------- VAR ----------------------------
  const item = [
    {
      color: "#000",
      children: <div className="timeline-logo" />,
    },
    {
      color: "#000",
      children: <PartnerLevelPartnerStructureTimeLineCountryManager />,
    },
    {
      color: "#000",
      children: <PartnerLevelPartnerStructureTimeLineAreaManager />,
    },
    {
      color: "#000",
      children: <PartnerLevelPartnerStructureTimeLineLeader />,
    },
    {
      color: "#000",
      children: <PartnerLevelPartnerStructureTimeLineDiamond />,
    },
    {
      color: "#000",
      children: <PartnerLevelPartnerStructureTimeLineGold />,
    },
    {
      color: "#000",
      children: <PartnerLevelPartnerStructureTimeLineSilver />,
    },
    {
      color: "#000",
      children: <PartnerLevelPartnerStructureTimeLineBronze />,
    },
  ];
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}> 
      <Timeline items={item} className="custom-time-line"/>
    </Col>
  );
};
export default PartnerLevelPartnerStructureTimeLine;
