import { LockOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import React from "react";

const LoginFormPassword = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: "Please input password",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
      ]}
    >
      <Input.Password
        prefix={<LockOutlined className="form-item-icon" />}
        placeholder="Password"
        type="password"
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default LoginFormPassword;
