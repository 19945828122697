import {
    collection,
    getDocs,
    getFirestore,
    query,
    where,
  } from "firebase/firestore";
  import { app } from "../../config/config";
  import { REF } from "../ref";
  import { TYPES } from "./client_list.type";
  
  const firestore = getFirestore(app);
  
  // fire base
  
  // action
  
  export const setActiveTab =
    ({ data }, callback) =>
    (dispatch) => {
        setActiveTabSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    };
    export const setOpenFiter =
    ({ data }, callback) =>
    (dispatch) => {
      setOpenFilterSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    };
  
  
  // dispatch
  const setActiveTabSuccess = (dispatch, data) => {
    dispatch({
      type: TYPES.SET_ACTIVE_TAB_SUCCESS,
      payload: data,
    });
  };
  const setOpenFilterSuccess = (dispatch, data) => {
    dispatch({
      type: TYPES.SET_OPEN_FILTER,
      payload: data,
    });
  };