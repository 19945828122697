import { Col, Row, Typography } from "antd";
import React from "react";
import { GoCheckCircle } from "react-icons/go";
import { IoIosCloseCircleOutline } from "react-icons/io";

//components
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelYourAreaManageDetailAction = ({isManage}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderActions = () => {
    if(isManage) {
      return (
        <IoIosCloseCircleOutline size={38} color="red" />
      )
    }
    return (
      <GoCheckCircle size={38} color="#016AFF" />
    )
  }
  // -------------------------- MAIN ---------------------------
  return (
    <Col xl={12} lg={12} md={12} xs={6}>
      <Row justify="end">
        {renderActions()}
      </Row>
    </Col>
  );
};
export default PartnerLevelYourAreaManageDetailAction;
