import { Form, Checkbox } from "antd";
import React from "react";

const LoginFormRemember = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item>
      <Form.Item name="remember" valuePropName="checked" noStyle>
        <Checkbox disabled={disabled}>Remember</Checkbox>
      </Form.Item>
    </Form.Item>
  );
};

export default LoginFormRemember;
