import { Layout, Row, Space } from "antd";
import React, {useEffect} from "react";
//components
import HomeScreenCardBalance from "./component/home-screen-card-balance";
import HomeScreenCardPromoteCode from "./component/home-screen-card-promote-code";
import HomeScreenCardChart from "./component/home-screen-card-chart"
import { useDispatch } from "react-redux";
//actions
import { setHighlight } from "../../store/nav/nav.action"
//selector
//function
//str
const { Content } = Layout;
const HomeScreen = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch()
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  useEffect(() => {
    dispatch(setHighlight(["1"]));
  }, [dispatch]);
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Layout className="full unselectable">
      <Content className="content pd-20">
        <Row gutter={[20, 20]} justify="center">
          <HomeScreenCardBalance />
          <HomeScreenCardPromoteCode />
          <HomeScreenCardChart />
        </Row>
      </Content>
    </Layout>
  );
};
export default HomeScreen;
