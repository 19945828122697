import { Col, Drawer, Input, Row } from "antd";
import React from "react";
import { FiFilter } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

//components
import BotTradingCommissionFilterInfo from "./component/promote_code_commission_filter_info"
//actions
import { setOpenFiter } from "../../../store/promoteCodeCommission/promote_code_commission.action";
//selector
import { openFilterSelector } from "../../../store/promoteCodeCommission/promote_code_commission.selector";
//function
//str

const PromoteCodeCommnissionFilter = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch()
  const openFilter = useSelector(openFilterSelector)
  // -------------------------- FUNCTION ---------------------
  const handelClose = () => {
    dispatch(setOpenFiter({data: false}))
  }
  const handleOpenClose = () => {
    dispatch(setOpenFiter({data:true}))
  }
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderIcon = () => {
    return <FiFilter onClick={handleOpenClose} />;
  };
  const renderFilter = () => {
    return (
      <Drawer
        placement="left"
        title="Filters"
        closable={true}
        onClose={() => handelClose()}
        visible={openFilter}
        className="custom-drawer-promote-code-commission"
        size="default"
      >
        <BotTradingCommissionFilterInfo />
      </Drawer>
    )
  }
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Row align="middle">
        <Col span={24}>
          <Input readOnly value="Filter" suffix={renderIcon()} />
        </Col>
      </Row>
      {renderFilter()}
    </Col>
  );
};
export default PromoteCodeCommnissionFilter;
