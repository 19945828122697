import { Typography, Row, Col } from "antd";
import React from "react";

//components
//actions
//selector
//function
//str

const { Text } = Typography;
const BotTradingCommissionTableDetailItemPercent = ({label, value, percent}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  
  return (
    <Row justify='space-between' align="center">
        <Text className="fs-12">{label}</Text>
        <Text className="fs-12">{value} {percent && `(${percent}%)`}</Text>
    </Row>
  );
};
export default BotTradingCommissionTableDetailItemPercent;
