import { Typography, Row, Input, Col, Drawer } from "antd";
import React from "react";
import { FiFilter } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

//components
import ClientListFilterInfo from "./component/client_list_filter_info"
//actions
import { setOpenFiter } from "../../../store/clientList/client_list_action"
//selector
import { openFilterSelector } from "../../../store/clientList/client_list.selector"
//function
//str

const { Text } = Typography;
const ClientListFilter = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch()
  const openFilter = useSelector(openFilterSelector)
  // -------------------------- FUNCTION ---------------------
  const handelClose = () => {
    dispatch(setOpenFiter({data: false}))
  }
  const handleOpenClose = () => {
    dispatch(setOpenFiter({data:true}))
  }
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderIcon = () => {
    return <FiFilter onClick={handleOpenClose} />;
  };
  const renderFilter = () => {
    return (
      <Drawer
        placement="left"
        title="Filters"
        closable={true}
        onClose={() => handelClose()}
        visible={openFilter}
        className="custom-drawer-client-list"
        size="default"
      >
        <ClientListFilterInfo/>
      </Drawer>
    )
  }
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Row align="middle">
        <Col span={24}>
          <Input readOnly value="Filter" suffix={renderIcon()} />
        </Col>
      </Row>
      {renderFilter()}
    </Col>
  );
};
export default ClientListFilter;
