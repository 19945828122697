import { combineReducers } from "redux";
import authReducer from "./auth/auth.reducer";
import homeReducer from "./home/home.reducer"
import clientListReducer from "./clientList/client_list.reducer"
import botTradingCommissionReducer from "./botTradingCommnission/bot_trading_commission.reducer"
import promoteCodeCommissionReducer from "./promoteCodeCommission/promote_code_commission.reducer"
import navReducer from "./nav/nav.reducer"
import partnerLevelReducer from "./partnerLevel/partner_level.reducer"

const rootReducer = combineReducers({
  authReducer,
  homeReducer,
  clientListReducer,
  botTradingCommissionReducer,
  promoteCodeCommissionReducer,
  navReducer,
  partnerLevelReducer
});

export default rootReducer;
