import { Button } from "antd";
import { useDispatch } from "react-redux";
//components
//actions
//selector
//type

//function
//str

const ClientListFilterInfoClientSubmit = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- REDUX -------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Button
        type="primary"
        form="form-filter-client"
        key="submit"
        htmlType="submit"
        className="w-full"
        size="middle"

      >
        Search
      </Button>
  );
};
export default ClientListFilterInfoClientSubmit;