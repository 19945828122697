import { TYPES } from "./client_list.type";
import { CLIENT_LIST_TAB } from "../string";

const initState = {
  activeTab: CLIENT_LIST_TAB.PROMOTE_CODE,
  openFilter: false,
};

const clientListReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set active tab
    case TYPES.SET_ACTIVE_TAB_SUCCESS:
      return {
        ...state,
        activeTab: payload,
      };
    // set open filter
    case TYPES.SET_OPEN_FILTER:
      return {
        ...state,
        openFilter: payload,
      };
    //default
    default:
      return state;
  }
};

export default clientListReducer;
