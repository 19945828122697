import React from "react";
import { Route } from "react-router-dom";
import { URL } from "./url";
// component
import LayoutRoot from "./layout/layout_root";
import PrivateRoute from "./component/private_route";
import HomeScreen from "../02_Home/home-screen"
import PartnerLevelScreen from "../03_Partner_Level/partner_level_screen"
import ClientListScreen from "../04_Client_List/client_list_screen"
import BotTradingCommissionScreen from "../05_Bot_Trading_Commission/bot_trading_commission_screen"
import PromoteCodeCommissionScreen from "../06_Promote_Code_Commission/promote_code_commission_screen"

const RoutePrivate = () => {
  // -------------------------- STATE --------------------------

  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Route exact path={URL.home} element={<LayoutRoot />}>
      <Route
        path={URL.home}
        element={<PrivateRoute element={<HomeScreen/>} />}
      />
      <Route
        path={URL.partnerLevel}
        element={<PrivateRoute element={<PartnerLevelScreen/>} />}
      />
      <Route
        path={URL.clientList}
        element={<PrivateRoute element={<ClientListScreen/>} />}
      />
      <Route
        path={URL.reports}
        element={<PrivateRoute element={<ClientListScreen/>} />}
      />
      <Route
        path={URL.botTradingCommission}
        element={<PrivateRoute element={<BotTradingCommissionScreen/>} />}
      />
      <Route
        path={URL.promoteCode}
        element={<PrivateRoute element={<PromoteCodeCommissionScreen/>} />}
      />
    </Route>
  );
};

export default RoutePrivate; 
