import { Col, Row, Typography } from "antd";
import React from "react";
//components
import PartnerLevelHeader from "./component/partner_level_header";
import PartnerLevelPartnerStructureTimeLine from "./component/partner_level_partner_structure_time_line"
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelPartnerStructure  = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
    const renderTitle = () => {
        return (
            <PartnerLevelHeader title="AI Fund Management - Partner Structure" />
        )
    }
    const renderTimeline = () => {
        return (
            <PartnerLevelPartnerStructureTimeLine />
        )
    }
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Row gutter={[0,16]}>
            {renderTitle()}
            {renderTimeline()}
        </Row>
    </Col>
  );
};
export default PartnerLevelPartnerStructure;
