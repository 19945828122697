import { Col, Row, Space, Typography } from "antd";
import React from "react";
//components

//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelPartnerStructureTimeLineLeaderItem = ({
  logo,
  commission,
  description,
}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderLogo = () => {
    return <Col>{logo}</Col>;
  };
  const renderInfo = () => {
    return (
      <Col span={20}>
        <Space direction="vertical">
          <Text>{commission}</Text>
          <Text>{description}</Text>
        </Space>
      </Col>
    );
  };
  // -------------------------- MAIN ------------------------
  return (
    <Col xl={20} lg={20} md={20} xs={16}>
      <Row>
        {renderLogo()}
        {renderInfo()}
      </Row>
    </Col>
  );
};
export default PartnerLevelPartnerStructureTimeLineLeaderItem;
