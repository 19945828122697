import { Row, Col, Card, Typography, Button, Space } from "antd";
import React from "react";
import { FaLink } from "react-icons/fa6";

//components
import HomeScreenCardHeader from "./component/home-screen-card-header";
import HomeScreenCardValue from "./component/home-screen-card-value"
//actions
//selector
//function
import { handleCoppy } from "../utils/general.function";
//str

const { Title } = Typography;

const HomeScreenCardPromoteCode = () => {
  // -------------------------- VAR ----------------------------
  const promoteCode = 606412;
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderHeader = () => {
    return (
      <HomeScreenCardHeader label="Promote Code" icon={<FaLink size={26} />} />
    );
  };
  const renderPromoteCode = () => {
    return (
      <HomeScreenCardValue value="606412" />
    );
  };
  const renderButtonCopy = () => {
    return (
      <Row justify="center">
        <Button className="w-150" onClick={() => handleCoppy(promoteCode)}>
          Copy
        </Button>
      </Row>
    );
  };
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Card className="custom-padding-card">
        <Space direction="vertical" className="w-full">
          {renderHeader()}
          {renderPromoteCode()}
          {renderButtonCopy()}
        </Space>
      </Card>
    </Col>
  );
};
export default HomeScreenCardPromoteCode;
