import { Layout, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux"

//components
import ClientListHeader from "./component/client_list_header"
import ClientListTab from "./component/client_list_tab"
import ClientListFilter from "./component/client_list_filter"
import ClientListStatistical from "./component/client_list_statistical"
import ClientListClientAccount from "./component/client_list_client_account"
//actions
import { setHighlight } from "../../store/nav/nav.action"

//selector
//function
//str
const { Content } = Layout;
const ClientListScreen = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch()
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  useEffect(() => {
    dispatch(setHighlight(["3","3.1"]));
  }, [dispatch]);
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Layout className="full unselectable">
      <Content className="content p-20">
        <Row gutter={[20, 18]}>
            <ClientListHeader />
            <ClientListTab />
            <ClientListFilter />
            <ClientListStatistical />
            <ClientListClientAccount />
        </Row>
      </Content>
    </Layout>
  );
};
export default ClientListScreen;
