import { TYPES } from "./promote_code_commission.type";

const initState = {
  openFilter: false,
};

const promoteCodeCommissionReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set open filter
    case TYPES.SET_OPEN_FILTER:
      return {
        ...state,
        openFilter: payload,
      };
    //default
    default:
      return state;
  }
};

export default promoteCodeCommissionReducer;
