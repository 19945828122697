import { Card, Space } from "antd";
import React from "react";

//components
import BotTradingCommissionTableDetailItem from "./component/bot_trading_commission_table_detail_item";
import BotTradingCommissionTableDetailItemCommission from "./component/bot_trading_commission_table_detail_item_commission";
import BotTradingCommissionTableDetailItemPercent from "./component/bot_trading_commission_table_detail_item_percent";
import BotTradingCommissionTableDetailExpend from "./component/bot_trading_commission_table_detail_expend"
//actions
//selector
//function
//str


const BotTradingCommissionTableDetail = ({record}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Card className="custom-expend-card">
        <Space direction="vertical" className="w-full">
            <BotTradingCommissionTableDetailItem label="Total Lot" value={record?.totalLot} />
            <BotTradingCommissionTableDetailItemPercent label="Clients Fund Allocationt" value={record?.clientsFundAllocation} percent={record?.percent} />
            <BotTradingCommissionTableDetailItemCommission label="Commission" value={record?.commission} />
            <BotTradingCommissionTableDetailExpend record={record?.details} />
        </Space>
    </Card>
  );
};
export default BotTradingCommissionTableDetail;
