import { Row, Col, Form, InputNumber } from "antd";
//components
//actions
//selector
//type

//function
//str

const ClientListFilterInfoClientUserName = ({ form }) => {
  // -------------------------- VAR ----------------------------
  const userName = Form.useWatch("userName", form);
  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form.Item
      label="Client Username"
      name="userName"
      value={userName}
    >
      <InputNumber
        placeholder="Username"
        value={userName}
        size="middle"
        className="w-full"
      />
    </Form.Item>
  );
};
export default ClientListFilterInfoClientUserName;