import { Typography, Row, Col } from "antd";
import React from "react";

//components
//actions
//selector
//function
//str

const { Text } = Typography;
const BotTradingCommissionTableDetailItemCommission = ({label, value}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderIcon = () => {
    if(value > 0) {
        return "+"
    } else if(value<0) {
        return "-"
    }
  }
  const renderColor = () => {
    if(value > 0) {
        return "success"
    } else if(value < 0) {
        return "danger"
    }
  }
  // -------------------------- MAIN ---------------------------
  
  return (
    <Row justify='space-between' align="center">
        <Text className="fs-12">{label}</Text>
        <Text type={renderColor()} className="fs-12">
          {`${renderIcon()} ${value} USD`}
        </Text>
    </Row>
  );
};
export default BotTradingCommissionTableDetailItemCommission;
