import { Col, Row, Typography } from "antd";
import React from "react";
//components
import PartnerLevelPartnerStructureMangeItem from "./component/partner_level_partner_structure_time_line_manage_item";
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelPartnerStructureTimeLineCountryManager = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderLogo = () => {
    return (
      <Col>
        <div className="time-line-item-icon time-line-item-icon-country" />
      </Col>
    );
  };
  const renderInfo = () => {
    return (
      <PartnerLevelPartnerStructureMangeItem
        title="Country Manager"
        commission="5% commission"
        description="Approved by LiveTrade and locked fund 10,000 USD"
      />
    );
  };
  // -------------------------- MAIN ------------------------
  return (
    <Col span={24}>
      <Row>
        {renderLogo()}
        {renderInfo()}
      </Row>
    </Col>
  );
};
export default PartnerLevelPartnerStructureTimeLineCountryManager;
