import { Row, Typography } from "antd";
import React from "react";

//components
//actions
//selector
//function
//str

const { Text } = Typography

const ClientListClientAccountInfoDetailItem = ({label, value}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Row justify="space-between">
        <Text>{label}</Text>
        <Text>{value}</Text>
    </Row>
  );
};
export default ClientListClientAccountInfoDetailItem;
