export const BOT_TRADING = [
    {
        index: 1,
        payDate: "30 Dec 2023",
        bot: "Bot01-John",
        commission: 1206,
        totalLot: 300.07,
        clientsFundAllocation: 2014,
        percent: 50,
        details: [
            {
                client:"Nghia Le",
                investedFund: "200.04 USD",
                fundAllocation: "5.00%"
            },
            {
                client:"Hana Ngoe",
                investedFund: "400.04 USD",
                fundAllocation: "9.81%"
            },
            {
                client:"Anh Nguyen",
                investedFund: "320.04 USD",
                fundAllocation: "6.71%"
            }
        ]
    }
]