import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//
import { newItems, newMenus, newBreadCrumb } from "../screen/00_Nav/url";

export const useMenu = () => {
  // -------------------------- VAR --------------------------
  const { t, i18n } = useTranslation("translation", { keyPrefix: "menu" });
  const language = i18n.language;
  // -------------------------- STATE --------------------------
  const [items, setItems] = useState({});
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [menu, setMenu] = useState([]);
  // -------------------------- REDUX --------------------------
  const categories = null;

  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    const items = newItems(t, "/" + language);
    const breadCrumb = newBreadCrumb(items);
    const menu = newMenus({ items, categories, language });
    setItems(items);
    setBreadCrumb(breadCrumb);
    setMenu(menu);
  }, [language, categories, t]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return { items, breadCrumb, menu };
};
