import { Row, Typography, Col, Space, Avatar } from "antd";
import React from "react";
//components
//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelYourAreaManageDetailInfo = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Col xl={12} lg={12} md={12} xs={18}>
      <Space size="middle">
        <Avatar
          src="https://www.w3schools.com/howto/img_avatar.png"
          size={42}
        />
        <Row>
          <Col>
            <Text>ericVuong@gmail.com</Text>
          </Col>
          <Col>
            <Text>EricVuong</Text>
          </Col>
        </Row>
      </Space>
    </Col>
  );
};
export default PartnerLevelYourAreaManageDetailInfo;
