import { Col, Space, Typography } from "antd";
import React from "react";
//components

//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelPartnerStructureTimeLineManageItem = ({title, commission, description}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ------------------------
  return (
    <Col span={16}>
        <Space direction="vertical" size={2}>
          <Text className="structure-title-color">{title}</Text>
          <Text>{commission}</Text>
          <Text>{description}</Text>
        </Space>
      </Col>
  );
};
export default PartnerLevelPartnerStructureTimeLineManageItem;
