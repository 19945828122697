import { Card, Col, Divider } from "antd";
import React from "react";

//components
import HomeScreenChartRegistrations from "./component/home-screen-chart-registrations";
import HomeScreenChartCommission from "./component/home-screen-chart-commission"
import HomeScreenCardChartFilter from "./component/home-screen-card-chart-filter"
//actions
//selector
//function
//str

const HomeScreenCardChart = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderChartRegistrationsChart = () => {
    return (
      <HomeScreenChartRegistrations />
    )
  }
  const renderCommision = () => {
    return (
      <HomeScreenChartCommission />
    )
  }
  const renderFilter = () => {
    return (
      <HomeScreenCardChartFilter />
    )
  }
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={24}>
      <Card className="custom-padding-card">
        {renderFilter()}
        {renderCommision()}
        <Divider />
        {renderChartRegistrationsChart()}
      </Card>
    </Col>
  );
};
export default HomeScreenCardChart;
