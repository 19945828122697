import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { LiaGripHorizontalSolid } from "react-icons/lia";
import { FaFlagCheckered } from "react-icons/fa";
import { HiOutlineDocumentReport } from "react-icons/hi";


export const URL = {
  // url
  home: "/",
  partnerLevel: "/partner-level",
  reports: "/reports",
  clientList: "/reports/client-list",
  botTradingCommission: "/reports/bot-trading-commission",
  promoteCode: "/reports/promote-code",
};

export const newItems = (t, language) => {
  const items = {
    home: {
      title: "Dashboard",
      url: URL.home,
      key: "1",
      hightlight: ["1"],
      arrow: false,
      icon: <LiaGripHorizontalSolid />,
    },
    partnerLevel: {
      title: "Partner Level",
      url: URL.partnerLevel,
      key: "2",
      hightlight: ["2"],
      arrow: false,
      icon: <FaFlagCheckered />
    },
    reports: {
      title: "Reports",
      url: URL.reports,
      key: "3",
      hightlight: ["3"],
      arrow: true,
      icon: <HiOutlineDocumentReport />
    },
    clientList: {
      title: "Client List",
      url: URL.clientList,
      key: "3.1",
      hightlight: ["3","3.1"],
      arrow: false,
    },
    botTradingCommission: {
      title: "Bot Trading Commission",
      url: URL.botTradingCommission,
      key: "3.2",
      hightlight: ["3","3.2"],
      arrow: false,
    },
    promoteCode: {
      title: "Promote Code Commission History",
      url: URL.promoteCode,
      key: "3.3",
      hightlight: ["3","3.3"],
      arrow: false,
    }
  };
  return items;
};

export const newBreadCrumb = (items) => {
  const breadCrumb = {
    home: null,
    // aboutUs: [items.home, items.aboutUs],
    // advisory: [items.home, items.aboutUs, items.advisory],
    // administration: [items.home, items.aboutUs, items.administration],
    // landing: [items.home, items.aboutUs, items.landing],
    // news: [items.home, items.news],
    // property: [items.home, items.property],
    // residential: [items.home, items.property, items.residential],
    // commercial: [items.home, items.property, items.commercial],
    // event: [items.home, items.event],
    // partner: [items.home, items.partner],
    // contact: [items.home, items.contact],
  };
  return breadCrumb;
};

export const setMenuItem = (props) => {
  const { title, url, key, arrow, icon, children } = props;
  const href = url;
  let label = href ? (
    <>
      <a href={href} target="_self" rel="noopener noreferrer">
        {title}
      </a>
      {arrow ? (
        <BsChevronDown
          style={{ fontSize: 10, marginLeft: 5 }}
          className="arrow-down"
        />
      ) : null}
    </>
  ) : (
    title
  );
  return { key, children, label, icon };
};

const setChildCategory = (type, categories, language) => {
  const data = categories
    ?.filter((item) => item?.type === type && item?.language === language)
    ?.map((item) => {
      return setMenuItem({
        title: item?.name,
        url: "/" + language + "/news/" + item?.type + "/" + item?.slug,
        key: item?.id,
        arrow: false,
        icon: null,
        children: null,
      });
    });
  return data?.length > 0 ? data : null;
};

export const newMenus = ({ items, categories, language }) => {
  const menu = [
    setMenuItem({ ...items.home }),
    setMenuItem({ ...items.partnerLevel }),
    setMenuItem({
      ...items.reports,
      children: [
        setMenuItem({ ...items.clientList }),
        setMenuItem({ ...items.botTradingCommission }),
        setMenuItem({ ...items.promoteCode }),
      ],
    }),
  ];
  return menu;
};
