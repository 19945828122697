import { Row, Col, Form, InputNumber } from "antd";
//components
//actions
//selector
//type

//function
//str

const ClientListFilterInfoClientLastInvestingVolume = ({ form }) => {
  // -------------------------- VAR ----------------------------
  const lastInvestingVolume = Form.useWatch("lastInvestingVolume", form);
  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form.Item
      label="Last investing volume"
      name="lastInvestingVolume"
      value={lastInvestingVolume}
    >
      <InputNumber
        placeholder="0 - 1,000 USD"
        value={lastInvestingVolume}
        size="middle"
        className="w-full"
      />
    </Form.Item>
  );
};
export default ClientListFilterInfoClientLastInvestingVolume;