import { Layout, Row, Space, Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux"
//components
import PartnerLevelRecords from "./component/partner_level_records"
import PartnerLevelYourAreaManager from "./component/partner_level_your_area_manager"
import PartnerLevelPartnerStructure from "./component/partner_level_partner_structure"
//actions
import { setHighlight } from "../../store/nav/nav.action"

//selector
//function
//str
const { Content } = Layout;
const PartnerLevelScreen = () => {
  // -------------------------- VAR ----------------------------
  // -------------------------- STATE ----------------------------
  // -------------------------- REDUX ----------------------------
  const dispatch = useDispatch()
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  useEffect(() => {
    dispatch(setHighlight(["2"]));
  }, [dispatch]);
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Layout className="full unselectable">
      <Content className="content p-20">
        <Row gutter={[20, 20]}>
            <PartnerLevelPartnerStructure />
            <PartnerLevelYourAreaManager />
            <PartnerLevelRecords />
        </Row>
      </Content>
    </Layout>
  );
};
export default PartnerLevelScreen;
