import { Typography } from "antd";
import React from "react";

//components
//actions
//selector
//function
//str

const { Text } = Typography;
const BotTradingCommissionTableCommissionItem = ({commission}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderIcon = () => {
    if(commission > 0) {
        return "+"
    } else if(commission<0) {
        return "-"
    }
  }
  const renderColor = () => {
    if(commission > 0) {
        return "success"
    } else if(commission < 0) {
        return "danger"
    }
  }
  // -------------------------- MAIN ---------------------------
  
  return (
    <Text type={renderColor()} className="fs-12">
        {`${renderIcon()} ${commission} USD`}
    </Text>
  );
};
export default BotTradingCommissionTableCommissionItem;
