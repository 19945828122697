import { Row, Typography, Col, Card, Space } from "antd";
import React from "react";
//components

//actions
//selector
//function
//str

const { Text } = Typography;

const PartnerLevelRecordsInfoClient = ({label}) => {
  // -------------------------- VAR ----------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderLabel = () => {
    return (
        <Text>{label}</Text>
    )
  }
  const renderImgClient = () => {
    return (
        <div className="img-client-client" />
    )
  }
  // -------------------------- MAIN ---------------------------
  return (
    <Col span={11}>
        <Row justify='center' align="middle" className="h-120">
            <Space direction="vertical" size="small">
                {renderImgClient()}
                {renderLabel()}
            </Space>
        </Row>
    </Col>
  );
};
export default PartnerLevelRecordsInfoClient;
