import { Row, Typography } from "antd";
import React from "react";
import { Line } from "@ant-design/charts";


//components
//actions
//selector
//function
//str

const HomeScreenChartCommisionChart = () => {
  // -------------------------- VAR ----------------------------
  const data = [
    { label: 'Week 1', value: 38 },
    { label: 'Week 2', value: 41 },
    { label: 'Week 3', value: 39 },
  ];
  const config = {
    data,
    xField: 'label',
    yField: 'value',
    shapeField: 'smooth',
    tooltip: false,
    style: {
      lineWidth: 2,
    },
  };
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Line {...config} />
  );
};
export default HomeScreenChartCommisionChart;
