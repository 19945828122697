import moment from "moment"

export function disableDateRanges(range = { startDate: false, endDate: false }, dateFormat) {
    const { startDate, endDate } = range;
    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck = current && current < moment(startDate, dateFormat);
      }
      if (endDate) {
        endCheck = current && current > moment(endDate, dateFormat);
      }
      return (startDate && startCheck) || (endDate && endCheck);
    };
  }

export const convertDate = (value ) => {
  return `${moment(value*1000).utc().format('DD MMM YYYY - hh:mm A')} UTC`
}