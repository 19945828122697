import {
    collection,
    getDocs,
    getFirestore,
    query,
    where,
  } from "firebase/firestore";
  import { app } from "../../config/config";
  import { REF } from "../ref";
  import { TYPES } from "./partner_level.type";
  
  const firestore = getFirestore(app);
  
  // fire base
  
  // action
  
  export const setSearch =
    ({ data }, callback) =>
    (dispatch) => {
        setSearchSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    };
  
  // dispatch
  const setSearchSuccess = (dispatch, data) => {
    dispatch({
      type: TYPES.SET_SEARCH,
      payload: data,
    });
  };
  