import { TYPES } from "./partner_level.type";

const initState = {
  search: "",
};

const partnerLevelReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    // set search manager
    case TYPES.SET_SEARCH:
      return {
        ...state,
        search: payload,
      };
    //default
    default:
      return state;
  }
};

export default partnerLevelReducer;
