import { DatePicker, Form } from "antd";
import moment from "moment";
//components
//actions
//selector
//type

//function
//str

const PromoteCodeCommissionFilterInfoAccountRedeemedDate = ({ form }) => {
  // -------------------------- VAR ----------------------------
  const accountRedeemDate = Form.useWatch("accountRedeemDate", form);
  const dateFormat = "DD MMM YYYY";
  // -------------------------- REDUX -------------------------

  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return (
    <Form.Item
      label="Account redeemed date"
      name="accountRedeemDate"
      value={accountRedeemDate}
    >
      <DatePicker.RangePicker
        format={dateFormat}
        className="w-full"
        size="middle"
        disabledDate={(current) => current.isAfter(moment())}
      />
    </Form.Item>
  );
};
export default PromoteCodeCommissionFilterInfoAccountRedeemedDate;
